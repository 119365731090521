.App {
  text-align: center;
  font-color: #444444;
  font-weight: 400;
  background: rgb(35,210,30);
  background: radial-gradient(circle, rgba(35,210,30,1) 0%, rgba(16,117,11,1) 100%, rgba(70,52,134,1) 100%);
  padding: 20px 30px 50px 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4 {
  color: #ffffff;
  font-weight: 100;
}
h1 {
  padding: 0 0 0 0 !important;
  font-size: 2em;
}


.grid {
  border: 2px solid #0eff6a;
  margin: 20px 0 !important;
  border-radius: 30px !important;
  overflow: hidden;
  box-shadow: 5px 10px #085f25;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  margin: 0;
  padding: 0;background: white;
}

button {
  color: #333333;
  font-weight: 300;
  font-size: 1.5em;
  //letter-spacing: 0.1em;
  padding: 10px 20px;
  margin: 0;
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px !important;
  border: 1px solid #333333;
  background: white;
  box-shadow: 5px 10px #888888;
}
h3 {
  color: #ffffff;
  font-weight: 500;
}

.intro {
  margin: 0;
  background: white;
  padding: 10px 30px;
}
.play-button {
  color: white !important;
  font-weight: 600 !important;
  background-color: blue !important;
  margin: 10px 0 !important;
  border-radius: 10px !important;
  //min-width: 200px !important;
  background: white;
  margin: 0;
  position: absolute;
  bottom: -3%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.center {
  text-align: center;
}



